import axios from 'axios';
import { baseApiUrl} from '../../global';
import {getLocalStorage} from './storage';

const baseUrl=baseApiUrl;

let headers ={
    'Access-Control-Allow-Origin': '*',
    // Request methods you wish to allow
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // Request headers you wish to allow
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type,authorization',
    'Access-Control-Allow-Credentials': true
 };

// if(getLocalStorage("acessToken")){
//     headers.Authorization=`Bearer ${getLocalStorage("acessToken")}`
// }


const axiosInstance = axios.create({
   baseURL: baseUrl,
    headers
});

//axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


axiosInstance.interceptors.response.use(
    (response) =>
    new Promise((resolve,reject) =>{
        resolve(response);
    }),
    (error) =>{
       if(!error.response){
        new Promise((resolve,reject) =>{
           // console.log('error',error)
            reject(error);
        });
    } 
    if(error.response.status === 403 || error.response.status === 401){
        localStorage.removeItem("accessToken");
        new Promise((resolve,reject) =>{
        //    console.log('error',error)
            reject(error);
        });
    }else{
        new Promise((resolve,reject) =>{
            new Promise((resolve,reject) =>{
                //console.log('error',error)
                reject(error);
            });
        });
    }

    }
    )


export default axiosInstance;

