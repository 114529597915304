import React,{useEffect, useState} from "react";
import {FiX,FiCheck} from 'react-icons/fi';
import { get, put } from "../../store/config/register";
import { toast } from "react-toastify";

const initialState ={
    usuario: {
      Empresa_Id: 1,
      Bloco_Id: 0,
      Unidade_Id: 0
    }
  }

  
export default function Autorizados(){

    const [usuarios,setUsuarios]=useState([]);
    const [usuario,setUsuario]=useState(initialState.usuario);
    const [blocos,setBlocos] =useState([]);
    const [unidades,setUnidades]= useState([]);

    const Empresa_Id = 1

    useEffect(()=> {
        getAutorizacao();
    },[])
    
    useEffect(()=>{
        buscaBloco(usuario.Empresa_Id);
    },[usuario.Empresa_Id]);

    useEffect(()=>{
        buscaUnidade(usuario.Bloco_Id);
    },[usuario.Bloco_Id]);

  async   function buscaBloco(empresa_id){
        const blocs = await  get(`/Bloco/v1/Empresa/${empresa_id}`);
        blocs.unshift({id:0, nome:""})
        setBlocos(blocs);
    }

    async function AlteraStatus (id){
        const data = { status: false}
        await put(`/Login/v1/AlteraStatus/${id}`,data);
        await toast.success('Usuário desativado.')
        await getAutorizacao();
        window.location.reload()
    } 

    async function buscaUnidade(bloco_id){
        const unids = await  get(`/Unidade/v1/Bloco/${bloco_id}`);
        unids.unshift({id:0, nome:""})
        setUnidades(unids);
    }

    async function PesquisaLogin(){
        if (usuario.Unidade_Id !== 0) {
            const users =  await get(`/Login/v1/Unidade?Empresa_Id=${usuario.Empresa_Id}&Unidade_Id=${usuario.Unidade_Id}`);
            setUsuarios(users);
        }
    }

    async function getAutorizacao() {
        const result = await get(`/Login/v1/Autorizado/${Empresa_Id}`);
        setUsuarios(result);
      
    }

    function trocaBloco(e) {
        setUsuario({...usuario, Bloco_Id: parseInt(e.target.value)})
    }

    function trocaUnidade(e) {
        setUsuario({...usuario, Unidade_Id: parseInt(e.target.value)})
    }

    function renderSelectBloco(){    
        if(blocos !== undefined){
            return blocos.map(bloco =>{
                return (<option key={bloco.id} value={bloco.id}>{bloco.nome}</option>)
            })
        }        
    }

    function renderSelectUnidade(){    
        if(unidades !== undefined){
            return unidades.map(unidade =>{
                return (<option key={unidade.id} value={unidade.id}>{unidade.nome}</option>)
            })
        }        
    }

    function render(){
        return(
            <div>
                <div className="nome-visitante">
                <div className="form-group">
                  <label>Bloco:</label>
                  <select  name="Bloco" 
                    className="form-control"  
                    value={usuario.Bloco_Id}  
                    onChange={trocaBloco} >{renderSelectBloco()} </select>
                </div>
            </div> 

            <div className="nome-visitante">
                <div className="form-group">
                  <label>Unidade:</label>
                  <select  name="Unidade" 
                    className="form-control"  
                    value={usuario.Unidade_Id}  
                    onChange={trocaUnidade} >{renderSelectUnidade()} </select>
                </div>
            </div>
            <hr />

            <div id="botao">
                  <button className="btn btn-primary" onClick={e =>  PesquisaLogin() }>
                    Pesquisar
                  </button>
            </div>

            {renderTable()}
        </div>
        );
    }

    function renderTable(){
        return (
            <div className='tabela'>
                 <hr/>
                 <table>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Email</th>
                              <th scope="col">Bloco</th>
                            <th scope="col">Unidade</th>
                            <th scope="col">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios === undefined ? null : usuarios.map((item, index) => {
                            return(
                                <tr key={index}>
                                    <td data-label="Nome">{item.nome}</td>
                                    <td data-label="Email">{item.email}</td>
                                      <td data-label="Bloco">{item.bloco}</td>
                                    <td data-label="Unidade">{item.unidade}</td>                                   
                                    <td data-label="#">
                                        <button className="action" style={{backgroundColor: '#ff0000' }} onClick={async(e) => AlteraStatus(item.id)} >
                                            <FiX color="#FFF" size={17} />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                 </table>
            </div>
        );
    }
    return (
        <div>
          {render()}
        </div>
    );
}