import React, {useState,useEffect} from 'react'
import { post } from '../../store/config/register';
import { toast } from 'react-toastify';
import {FiX} from 'react-icons/fi';
import QRCode  from "qrcode";

import { format, isDate, parse } from 'date-fns';

import email from '../../assets/imgs/email.png';
import whatsapp from '../../assets/imgs/whatsapp.png';

import './Qrcode.css'

export default function QrCode({conteudo,close}){

    const [visitante,setVisitante]=useState(conteudo);
    const [src,setSrc]=useState('');
    
      async function GerarQrcode(){
        console.log(visitante);
            QRCode.toDataURL(visitante.qrcode,{
              width: 320,
              height: 320
             }).then(setSrc);
               
      }


      useEffect(() =>{
        GerarQrcode();
        
      },[])

      function downloadImage() {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = src;
        img.onload = () => {
          // create Canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          // create <a> tag
          const a = document.createElement("a");
          a.download = "download.png";
          a.href = canvas.toDataURL("image/png");
          console.log( encodeURIComponent(a) )
    
          a.click();
        };
      }

      async function enviarWhatsapp(){

        const data ={base64: src.toString().replace('data:image/png;base64,',''), id: parseInt(visitante.qrcode) }
        console.log(data);
        let url =  await post('/Imagem/v1',data);


        // const contentType = 'image/png';
        // const blob = b64toBlob(src.replace('data:image/png;base64,',''), contentType);
        // const blobUrl = URL.createObjectURL(blob);

        let teste =parse(visitante.inicio,"yyyy-MM-dd'T'HH:mm:ss",new Date());
        if(isNaN(teste.getMonth())){
          teste = visitante.inicio;
          teste =format(parse(teste,'yyyy-MM-dd',new Date()),'dd/MM/yyyy');

        }else {
          teste =format(teste,'dd/MM/yyyy')
        }
        console.log(teste)
         let encodedURL = encodeURIComponent(url);
         let message=`https://api.whatsapp.com/send?phone=55${visitante.celular}&text=Olá ${visitante.nome}, sua visita foi agendada para o dia  ${teste} por: ${visitante.anfitriao}, para acessar a piscina utilize o QRCode no link abaixo: ${encodedURL} - Condomínio Lavillette`;
         window.location.replace(message);
      }

      function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

      const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

      async function enviarEmail(){
        let emails =[];
         emails.push(visitante.email);
      
         let data =  { sender:emails, subject: 'QRCode Acesso',content: 'Segue em anexo o QrCode:', image: src.toString().replace('data:image/png;base64,',''), empresa_id: visitante.empresa_Id}
         let result =await post('/Email/v1',data);
         await toast.success('email enviado com sucesso');
      }


           

    function render(){
        
        return (
            <div className="modalQrcode">
                <div className="containerQrcode">
                    <button className="closeQrcode" onClick={ close }>
                        <FiX size={23} color="#FFF" />
                        Voltar
                    </button>
                    <div className='buttonSend'>
                        <img src={src} width={250} height={250} alt="Piscina" data-toggle="tooltip" data-placement="bottom" title="Precione para Compartilhar Qrcode"/>
                        <label style={{fontWeight: 'bold'}}>Compartilhar:</label>
                        <div >
                        <img src={email} width={60} height={60}  alt="Foto de perfil do usuario" style={{marginRight: '15px'}} data-toggle="tooltip" data-placement="bottom" title="Envio Email" onClick={e => enviarEmail()} />
                        <img src={whatsapp} width={60} height={60} med alt="Foto de perfil do usuario" data-toggle="tooltip" data-placement="bottom" title="Compartilhar via WhatsApp" onClick={e => enviarWhatsapp()} />                           
                        </div>     
                    </div>
                    </div>
            </div>
        );
    }
    return (
        <div>
            {render()}
        </div>
    );
}