import React, {useState,useEffect} from "react";
import { format,parse } from "date-fns";
import {get,del} from '../../store/config/register';
import {FiX,FiEdit2,FiPlusCircle,FiShare2,FiCalendar} from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import Messagem from "../../components/mensagem/Messagem";
import './VisitanteUsuario.css'

import { getLocalStorage } from "../../store/config/storage";
import { authKey,secretKey } from "../../global";
import CryptoJS from "crypto-js";

import Main from "../../components/template/Main";
import QrCode from "../Qrcode/Qrcode";
import { toast } from "react-toastify";

let Empresa_Id =0;
let Anfitriao_Id =0;

const headerProps = {
  icon: "users",
  title: "Visitantes",
}

export default function VisitanteUsuario() {
    const history = useHistory();
    const user =JSON.parse(getLocalStorage(authKey)) 
    const [usuario,setUsuario]=useState({});
    const [visitantes,setVisitantes]=useState([]);
    const [visitante,setVisitante]=useState({});
    const [showPostPesquisa, setShowPostPesquisa] = useState(false);
    const [showPostMensagem, setShowPostMessagem] = useState(false);
    const [messagem,setMessagem]=useState({});

    async function VerificaUsuario(){

      let bytes  = CryptoJS.AES.decrypt(user.Token, secretKey).toString(CryptoJS.enc.Utf8);
      let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      Anfitriao_Id = decryptedData.split('/')[0];
       Empresa_Id = decryptedData.split('/')[2];
    
      if(Anfitriao_Id > 0){
        const  result = await  get(`/Login/v1/${Anfitriao_Id}`);
        if(result !== null){
          setUsuario(result);
        }
     }
    
    }

    useEffect (() => {
      VerificaUsuario();
      buscaVisitantes(Anfitriao_Id);
    },[])

    function buscaVisitantes(AnfitriaoId){
        const funcs = get("/Visitante/v1/Anfitriao/"+AnfitriaoId);
        if(funcs != null){
            funcs.then(resp => {
                setVisitantes(resp);
           })
        }
    }

    function togglePostModal(item){
      if(menorHoje(item) === false) {
        setVisitante({...item, mudar: true});
        setShowPostPesquisa(!showPostPesquisa) //trocando de true pra false
      }else {
        toast.error("Visita vencida")
      }

  
  }

  function togglePostMessagemModal(item){
    setMessagem({...item, messagem:'Confirma exclusão do Visitante '+ item.nome +" ?"})
    setShowPostMessagem(!showPostMensagem) //trocando de true pra false
   
  
  }
    function Edit(Id){
      history.push(`/Visitante/${Id}`)
    }

    async function Delete(Id){
      console.log(Id);
    await  del(`/Visitante/v1/${Id}`)
    await toast.success('Visitante apagado.')
     window.location.reload();
    }


    function Agenda(Id){
      history.push(`/Visitante/Agenda/${Id}`)
    }
    function renderCabec(){
      return (
        <div className="bloco">
        <label>{`Bloco: ${usuario.bloco === undefined ? '' : usuario.bloco}`}</label>
        <label>{`Unidade: ${usuario.unidade === undefined ? '' : usuario.unidade}`}</label>
      <div>
        
      <button  style={{backgroundColor: '#007BFF', color: '#FFF', borderRadius: '6px', with:'90px',height: "40px"}} onClick={(e) => history.push('/Visitante')} >
        <FiPlusCircle color="#FFF" size={23} style={{marginRight: '10px'}}/>
        Incluir Visitante
      </button>
      <hr/>
      </div>
      {render()}
      {showPostPesquisa && (
            <QrCode
            conteudo={{...visitante}}
            close={togglePostModal}
            />
            )}
            {showPostMensagem && (
            <Messagem
            conteudo={{...messagem}}
            close={togglePostMessagemModal}
            />
            )}
      </div>
      );
      
    }

    function menorHoje(visita){
      let fim =parse(visita.fim,"yyyy-MM-dd'T'HH:mm:ss",new Date());
      let agora = parse(format(new Date(),'yyyy-MM-dd'),'yyyy-MM-dd',new Date());

      if(fim < agora){
        return true;
      }else{
        return false;
      }
    }

    function render(){
        return (
          <div className='tabela'>
          <table>
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Nascimento</th>
                <th scope="col">Email</th>
                <th scope="col">Celular</th>
                <th scope="col">Data da Visita</th>
                <th scope="col"></th>
              </tr>
            </thead>
              <tbody>
                {visitantes === undefined ? null :visitantes.map((item, index)=>{
                  return(
                    <tr key={index} style= {menorHoje(item) === true? ({backgroundColor: '#CCC'}): null}>
                      <td data-label="Nome">{item.nome}</td>
                      <td data-label="Nascimento">{item.dT_Nascimento !== null ?format(parse(item.dT_Nascimento,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy'):item.dT_Nascimento }</td>
                      <td data-label="Email">{item.email}</td>
                      <td data-label="Celular">{item.celular}</td>
                      <td data-label="Data da Visita">{`${format(parse(item.inicio,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy')}`}</td>
                      <td  data-label="">
                      <button className="action" style={{backgroundColor: '#007BFF',marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Compartilhar" onClick={(e) => {togglePostModal(item)}} >
                        <FiShare2  color="#FFF" size={17} />
                        </button>
                      <button className="action" style={{backgroundColor: '#F99E08' ,marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={(e) => {Edit(item.id)}} >
                        <FiEdit2 color="#FFF" size={17} />
                        </button>
                        <button  className="action" style={{backgroundColor: '#00ad43',marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Agendar Visita"  onClick={(e) => {Agenda(item.id)}} >
                        <FiCalendar color="#FFF" size={17} />
                        </button>
                        <button className="action" style={{backgroundColor: '#ff0000', marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Visitante"  onClick={async(e) => await togglePostMessagemModal(item)} >
                        <FiX color="#FFF" size={17} />
                        </button>
                        </td>
                    </tr>
                  )     
                })}
              </tbody>
            </table>
      </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderCabec()}
        </Main>
    );
}