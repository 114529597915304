import React, {useState,useEffect} from "react";
import Main from "../../components/template/Main";

import avatar from '../../assets/imgs/avatar.png';

import { secretKey} from "../../global";

import { post, put } from "../../store/config/register";
import CryptoJS from "crypto-js";

const headerProps = {
    icon: "envelope",
    title: "Confirmação de Email",
  }

export default function ConfirmacaoEmail (props) {


    const dados =props.match.params.dados;
    
    let nome ='';

  async  function VerificaConfirmacao(){
        let bytes  = CryptoJS.AES.decrypt(dados, secretKey).toString(CryptoJS.enc.Utf8);
        let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    
        let email =decryptedData.split('/')[0];

      const usu=  await Validar(email);
      nome = usu.nome;
        if(usu !== undefined){
            const data={confirmar: true};
            await put('/Login/v1/AlteraConfirmacao/'+usu.id, data);

        }
    }


    useEffect(()=>{
        if(dados !== undefined) {
            VerificaConfirmacao();
        
        }
    },[]);

    async function Validar(email){
        const data ={email: email};
     let usu = await post('/Login/v1/Validar', data);
        
     return usu;
    }

    function render() {

        return (
            <div className="aviso">
                <img src={avatar} width={90} height={105} alt="Foto de perfil do usuario" />
                <div className="autorizacao">
                <label>{nome}</label>
                <label>Conta confirmada. Aguarde autorização para acessar o aplicativo.</label>
                </div>

            </div>
        );
    }
    return(
        <Main {...headerProps}>
        {render()}
    </Main>
    );
    
}