import React,{useState,useEffect} from "react";
import {FiX,FiCheck} from 'react-icons/fi';
import { get, put } from "../../store/config/register";
import { toast } from "react-toastify";
import './AutorizacaoAcesso.css';
import { getLocalStorage } from "../../store/config/storage";
import { authKey,secretKey } from "../../global";
import { format,parse } from "date-fns";
import CryptoJS from "crypto-js";



export default  function AutorizacaoAcesso(){
    const user =JSON.parse(getLocalStorage(authKey));
    const [EmpresaId, setEmpresaID]  = useState(0);
    let bl =[];
    const [selectBlocos, setSelectBlocos] =useState([]);
    const [selectUnidades,setSelectUnidades]=useState([]);
    const [arrayUnidades,setArrayUnidades] = useState([]);
    const [usuarios,setUsuarios]=useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [blocos,setBlocos] =useState([]);
    const [unidades,setUnidades]= useState([]);
    const [moradores,setMoradores]=useState([]);
    const [anfitriaID,setAnfitriaoID]=useState(0);


   const handleEpandRow = async(event, userId,item) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) :  (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
          currentExpandedRows.filter(id => id !== userId) :
          currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);

    let array =[];
    selectBlocos.forEach((item)=>{
        array.push (unidades.filter((unid) => unid.bloco_Id === item)) ;
        setArrayUnidades(array);
    })

    
   

  }

  useEffect(()=>{
    montadados();
   
  },[selectUnidades])

  async function montadados(){
    let array =[];

    selectUnidades.forEach(async (item,index)=> {
       const resultado = await buscaMorador(item);
       array[index]=resultado;
       setMoradores(array);
    })
  }

  async function VerificaUsuario(){
    let bytes  = CryptoJS.AES.decrypt(user.Token, secretKey).toString(CryptoJS.enc.Utf8);
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
   setEmpresaID(parseInt(decryptedData.split('/')[2]))  ;

    await getAutorizacao(parseInt(decryptedData.split('/')[2]));
}
  useEffect( () => {
    VerificaUsuario();
   
        },[])

      async function getAutorizacao(Empresa_Id) {
            const result = await get(`/Login/v1/NaoAutorizado/${Empresa_Id}`);
        setUsuarios(result);


        let un =[];
        result.map((item) => {
            un.push(item.unidade_Id);
            bl.push(item.bloco_Id);        
        })

        setSelectBlocos(bl);
        setSelectUnidades(un);
    }

    useEffect(()=>{
        buscaBloco(EmpresaId);
        buscaUnidade(EmpresaId);

    },[EmpresaId]);

    

    async function buscaBloco(empresa_id){
        const blocs = await   get(`/Bloco/v1/Empresa/${empresa_id}`);
        if(blocs != null){
            blocs.unshift({id:0, nome:""});
            setBlocos(blocs);
        }
    }

    async function buscaUnidade(empresa_id){
        const unids = await  get(`/Unidade/v1/Empresa/${empresa_id}`);
        if(unids != null) {
        //    unids.unshift({id:0, nome:""})
            setUnidades(unids);
        }
    }

    async function buscaMorador(unidade_id) {
        const mora = await get(`/Pessoa/v1/Unidade?unidade_Id=${unidade_id}&empresa_Id=${EmpresaId}`);
        return mora;
    }

    function renderSelectBloco(){    
        if(blocos !== undefined){
            return blocos.map(bloco =>{
                return (<option key={bloco.id} value={bloco.id}>{bloco.nome}</option>)
            })
        }        
    }

    function renderSelectUnidade( index){    
        if(arrayUnidades[index] !== undefined){
            return arrayUnidades[index].map(unidade =>{
                return (<option key={unidade.id} value={unidade.id}>{unidade.nome}</option>)
            })
        }        
    }

    function onChangeRadio(e) {
        setAnfitriaoID(parseInt(e.target.value));
    }

    function trocaBloco(e,index) {
        selectBlocos[index]=parseInt(e.target.value);

        let array =[];
        selectBlocos.forEach((item)=>{
            array.push (unidades.filter((unid) => unid.bloco_Id === item)) ;
            setArrayUnidades(array);
        })
    }

   async function trocaUnidade(e, index) {
        selectUnidades[index]=parseInt(e.target.value);
       const result =  await buscaMorador(parseInt(e.target.value));

       let array=[];

       
       moradores.map((item,ind)=>{
        if(ind === index){
            array.push(result);
        }else { 
            array.push(item);
        }       
        
       })

       setMoradores(array);

        }

    async function VincularUsuario (id,bloco_id,unidade_id){
        const data = { idreplicacao: anfitriaID, bloco_id: bloco_id,unidade_id: unidade_id}
        await put(`/Login/v1/IdReplicacao/${id}`,data);
         }

    async function AlteraStatus (id,bloco_id,unidade_id){
        if(anfitriaID !== 0){
            await  VincularUsuario(id,bloco_id,unidade_id);
        const data = { status: true}
        await put(`/Login/v1/AlteraStatus/${id}`,data);
      await  toast.success('Usuário ativado com sucesso');
       getAutorizacao();
      window.location.reload();
        }else {
            toast.warning("Selecione um morador");
        }
        
    } 

    function render(){
        return (
            <div>
                 <hr/>
                 <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Email</th>
                              <th scope="col">Bloco</th>
                            <th scope="col">Unidade</th>
                            <th scope="col">#</th>
                        </tr>
                    </thead>
                   
                        {usuarios === undefined ? null : usuarios.map((item, index) => { 
              
                           
                               return(
                                <tbody>
                                <tr key={index}>
                                    <td data-label="Nome">{item.nome}</td>
                                    <td data-label="Email">{item.email}</td>
                                      <td data-label="Bloco">{item.bloco}</td>
                                    <td data-label="Unidade">{item.unidade}</td>                                                   
                                    <td data-label="#" className ="expand-button">
                                        <button className="action btn btn-success"  style={{marginLeft: "10px"}} onClick={(e) =>  handleEpandRow(e, item.id,item)} >
                                       { expandState[item.id] ?
                                        '' : 'Autorizar'}
                                        </button>
                                    </td>
                                </tr>
                                <>
                                {expandedRows.includes(item.id) ?
                                <tr className="body">
                                    
                                <div className="form">
                                <div className="nome-visitante">
                                    <div className="form-group">
                                    <label>Bloco:</label>
                                    <select  name="Bloco" 
                                        className="form-control"  
                                        value={selectBlocos[index]}  
                                        onChange={(e) => trocaBloco(e,index)} >{renderSelectBloco()} </select>
                                    </div>
                                </div> 

                                <div className="nome-visitante">
                                    <div className="form-group">
                                    <label>Unidade:</label>
                                    <select  name="Unidade" 
                                        className="form-control"  
                                        value={selectUnidades[index]}  
                                        onChange={(e) =>trocaUnidade(e,index)} >{renderSelectUnidade(index)} </select>
                                    </div>
                                </div>
                                   <label>Selecione o Morador</label>    
                                <hr/>
                                <table className="expansive">
                                <thead>
                                    <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Parentesco</th>
                                    <th scope="col">Nascimento</th>
                                    <th scope="col">#</th>
                                    </tr>
                                </thead>
                                {moradores[index] === undefined ? null : moradores[index].map((item,index2) => {
                                    console.log(moradores,index);
                        return (
                            <tbody>
                                <tr key={index2}>
                                <td data-label="Nome">{item.nome}</td>
                                <td data-label="Parentesco">{item.parente}</td>
                                <td data-label="Nascimento">{item.data_Nascimento === null ?'': format(parse(item.data_Nascimento,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy')}</td>
                                <td data-label="#">
                                <div class="radio">
                                    <label><input type="radio" value={item.id_Replicacao} onChange={(e)=> onChangeRadio(e)} name="optmorador"/></label>
                                </div>
                                </td>
                                </tr>
                            </tbody>
                        );
                    })}
                                </table>
                                <hr />
                                <div className="nova">
                                    <button className=" btn btn-success" onClick={async(e) =>  AlteraStatus(item.id,selectBlocos[index],selectUnidades[index])}>Autorizar</button>
                                </div>

                                </div>    
                                
                            </tr>
                            :null}
                                
                                </>                                
                            </tbody>
                            );
                        })}
                    
                 </table>
            </div>
        );
    }
    return (
        <div>
            {render()}
        </div>
    );
}