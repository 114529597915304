import React,{useState} from "react";

import Main from "../../components/template/Main";
import { useHistory } from "react-router-dom";
import { setLocalStorage } from "../../store/config/storage";
import validator from 'validator';
import { post } from "../../store/config/register";

const initialState ={
    usuario: {
      Id: 0,
      Empresa_Id: 0,
      Nome:'',
      Senha: '',
      Email:'',
      ConfirmarSenha: ''
    }
  }

  const headerProps = {
    icon: "edit",
    title: "Cadastro",
    subtitle: "Cadastro de usuário"
}
export default function CadastrarUser(){

    const [usuario,setUsuario] = useState(initialState.usuario);
    const [error,setError] = useState('');
    const history = useHistory(); 

    function  updateField(event) {
        const usu ={...usuario}
          
        usu[event.target.name] =event.target.value
        setUsuario(usu);
    }

    async function Cadastrar() {
        
        if(await Validar()) {
         setLocalStorage('user',usuario);
         history.push('/Condominio')
        }
        
    }

   async function Validar() {
        if(usuario.Nome === ''){
            setError('Preencha o nome');
            return false;
        }

        if(usuario.Email === '') {
            setError('Preencha o email');
            return false;
        }else {
            if(validator.isEmail(usuario.Email) === false) {
                setError('E-mail inválido.')
                return false;
            }else {
                const data ={email: usuario.Email};
               const email = await post('/Login/v1/Validar',data)
               if(email !== ''){
                setError('E-mail já cadastrado!');
                return false;
               }
            }
        }

        if(usuario.ConfirmarSenha !== usuario.Senha) {
            setError('A confirmação da senha deve ser igual a senha.');
            return false;
        } else {
            if(usuario.Senha.length < 6) {
                setError('a senha deve ter 6 caracteres');
                return false;
            } else {
                if(valida(usuario.Senha) === true) {
                    return true;
                }else {
                    setError('A senha deve conter letras, números e caracteres especiais');
                    return false;
                }
            }
        }
    }

    function valida(senha) {
        let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z])(?!\1)|[!@#$%;*(){}_+^&]){6,}$/;
        
        if (regex.test(senha) === true) {
           return true;
        } else {
            return false;
        }
    }

    function render() {
        return (
            <div className="form">
                <div className="email">
                <div className="form-group">
                  <label>Nome:</label>
                  <input type="text" name="Nome" 
                  placeholder="Nome"
                    className="form-control" value={usuario.Nome} 
                    onChange={e => updateField(e)} />
                </div>
            </div> 
            <div className="email">
              <div className="form-group">
                  <label>E-mail:</label>
                  <input type="email" name="Email" 
                  placeholder="Email"
                  className="form-control" value={usuario.Email} 
                  onChange={e => updateField(e)} />
              </div>
            </div>
            <div className="email">
                <div className="form-group">
                  <label>Senha:</label>
                  <input type="password" name="Senha" 
                    placeholder="Senha"
                    className="form-control" value={usuario.Senha} 
                    onChange={e => updateField(e)} />
                </div>
            </div>
            <div className="email">
                <div className="form-group">
                  <label>Confirmar Senha:</label>
                  <input type="password" name="ConfirmarSenha" 
                  placeholder="Confirmação de Senha"
                    className="form-control" value={usuario.ConfirmarSenha} 
                    onChange={e => updateField(e)} />
                </div>
            </div>
            <label style={{color: '#FF0000'}}>{error}</label>
            <label style={{fontWeight: 'bold'}}>*A senha deve conter no minímo 6 caracteres entre letras, números e caracteres especial</label>
            <hr/>
            <div id="botao">
                  <button className="btn btn-primary" onClick={async (e) => Cadastrar()}>
                    Cadastrar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => history.push('/')}>
                    Cancelar
                  </button>
            </div>
            </div>
        );
    }
    
    return (
    <Main {...headerProps}>
        {render()}
    </Main>
    );
}