import './Nav.css'
import React, { Component, useState } from 'react'
import 'font-awesome/css/font-awesome.min.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom'
import { isAdmin } from '../../store/config/auth'
import { getLocalStorage } from '../../store/config/storage'; 
import { authKey } from '../../global';
import { useHistory } from 'react-router-dom';




export default function Nav () {
    let user=getLocalStorage(authKey);
    const history= new useHistory();
    const [clicked,setClicked] =useState(false);
 

    
  if(user){
     user = JSON.parse(user);
  }

  function logout(){
    localStorage.clear(authKey);
    history.push("/");
    window.location.reload();
  }


   function handleClick  () {
         setClicked(!clicked)
    }

    return (
        <aside className="menu-area" >
            <nav className="menu" >
                <div id="menu-div">
                    
                        {isAdmin() === true? (
                            <ul id="menu-desktop" className={clicked ? "#menu-desktop active" : "#menu-desktop"}>
                        <NavLink exact to="/Autorizados">
                            <i className="fa fa-user"></i> Autorizados
                        </NavLink>
                        </ul>
                        ) 
                        : 
                        (
                        <ul id="menu-desktop" className={clicked ? "#menu-desktop active" : "#menu-desktop"}>
                        <NavLink exact to="/Visitantes">
                            <i className="fa fa-user"></i> Visitantes
                        </NavLink>
                        </ul>
                        )}
                        
                        
                        
                    
                </div>

                <div id="mobile" onClick={() =>handleClick()}>
                    <i
                    id="bar"
                    className={clicked ? 'fas fa-times' : 'fa fa-bars' }
                    ></i>

                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {user.nome}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className='dropdowitem' href="/">Home</Dropdown.Item>
                            <Dropdown.Item className='dropdowitem' onClick={()=>logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </nav>
        </aside>
    );
}
