import React from "react";
import Main from "../../components/template/Main";
import { Tabs,Tab } from "react-bootstrap";
import AutorizacaoAcesso from "./AutorizacaoAcesso";
import Autorizados from "./Autorizados";
import './TabAutorizacao.css'

const headerProps = {
    icon: "address-card",
    title: "Autorização de Usuário",
  }

export default function TabAutorizacao() {

    function renderTab(){
        return (
            <Tabs>
            <Tab title="Login Pendente" key={"1"} eventKey="Tab 1">
                <div id="1">
                 <AutorizacaoAcesso />
                </div>
            </Tab>
            <Tab title="Autorizados" key={"2"} eventKey= "Tab 2">
                <div id="2">
                 <Autorizados />
                </div>
            </Tab>
        </Tabs>
        );
    }

    return(
        <Main {...headerProps}>
            {renderTab()}
        </Main>
    );
}