import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isAdmin } from '../store/config/auth'

import Visitante from '../pages/Visitantes/Visitante';
import VisitanteUsuario from '../pages/Visitantes/VisitanteUsuario';
import Auth from '../pages/Auth/Auth';
import  Condominio  from '../pages/Condominio/Condominio';
import Autorizacao from '../pages/Autorizacao/Autorizacao';
import TabAutorizacao from '../pages/Autorizacao/TabAutorizacao';
import CadastrarUser from '../pages/Auth/CadastrarUser';
import ConfirmacaoEmail from '../pages/Autorizacao/ConfirmacaoEmail';
import TestCrypto from '../components/Teste/Teste';
import AgendaVisitante from '../pages/Visitantes/AgendaVisitante';
import QrCodeLeitura from '../pages/Qrcode/QrcodeLeitura';

const PrivateRoute = ({component: Component,...rest}) => (
    <Route {...rest} render={props =>
    isAuthenticated()  ? (
        isAdmin() === true ? 
        (<Redirect to={{ pathname: '/Autorizados', state:{from: props.location}}}/>) 
        :
        (<Component {...props}/>) 
        
    ) 
    :
    (
        <Redirect to={{ pathname: '/Auth', state:{from: props.location}}}/>
      
    )
   
    }/>
    
    );

    const AdminRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated()  ? (
            isAdmin() === false ? 
            (<Redirect to={{ pathname: '/Visitantes', state:{from: props.location}}}/>) 
            :
            (<Component {...props}/>) 
            
        ) 
        :
        (
            <Redirect to={{ pathname: '/Auth', state:{from: props.location}}}/>
          
        )
       
        }/>
        
        );
    
    const OpenRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated() === false ? (
            <Component {...props}/>
        ) 
        :
        (
            <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
        )
        
        }/>
        );

        const FreeRoute = ({component: Component,...rest}) => (
            <Route {...rest} render={props =>
            isAuthenticated() === false ? (
                <Component {...props}/>
            ) 
            :
            (
                <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
            )
            
            }/>
            );

export default function Routes () {
    return (
        <Switch>
            <PrivateRoute exact path="/Visitante" component={ Visitante}/>
            <PrivateRoute exact path="/Visitante/:id" component={ Visitante}/>
            <PrivateRoute exact path="/Visitantes" component={ VisitanteUsuario}/>
            <PrivateRoute exact path="/Visitante/Agenda/:id" component={ AgendaVisitante}/>
            <OpenRoute exact path="/Condominio" component={ Condominio}/>
            <OpenRoute exact path="/Auth/:id" component={Auth}/>
            <OpenRoute exact path="/Auth" component={Auth}/>
            <OpenRoute exact path="/Autorizacao" component={ Autorizacao}/>
            <OpenRoute exact path="/Autorizacao/:nome" component={ Autorizacao}/>
            <AdminRoute exact path="/Autorizados" component={ TabAutorizacao}/>
            <OpenRoute exact path="/Cadastrar" component={CadastrarUser} />
            <OpenRoute exact path="/Confirmar/:dados" component={ConfirmacaoEmail} />
            <OpenRoute exact path="/Confirmar" component={ConfirmacaoEmail} />
            <Route exact path="/QRCode/:qrcode" component={QrCodeLeitura} />
            <OpenRoute exact path="/Teste" component={ TestCrypto}/>
            <Redirect from='*' to='/Auth'/>
        </Switch>
    );
} 
    