import { authKey } from "../../global";
import {getLocalStorage} from './storage';

export const isAuthenticated = () => {

 const user=getLocalStorage(authKey);
    if(user){
         return true;
     }else{
         return false;
    }
    
};

export const isFreeRoute = () => {

    const user=getLocalStorage(authKey);
       if(user){
            return true;
        }else{
            return false;
       }
       
   };

export const isAdmin = () => {

    const user=JSON.parse(getLocalStorage(authKey));

    if(user) {
        console.log(user);
        if (user.Admin) {
            return true;
        }else {
            return false;
        }
    }else {
        return false;
    }
}