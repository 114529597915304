import React, { useState,useEffect } from "react";
import Main from "../../components/template/Main";
import './Autorizacao.css'

import avatar from '../../assets/imgs/avatar.png';
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../store/config/storage";

const headerProps = {
    icon: "address-card",
    title: "Autorização",
  }
export default function Autorizacao(props) {
    const [nome,setNome]= useState(props.match.params.nome);
    const history = useHistory();
    let messagem = JSON.parse(getLocalStorage("messagem"));

    console.log(messagem);
    
    if(messagem === undefined){
        messagem ="Aguardando autorização do Condomínio...";
    }

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

       async function fetchData() {
  
         await sleep(4000);
         history.push('/');
       }

    //    useEffect(()=> {
    //      fetchData();
    //    },[])

    function render() {

        return (
            <div className="aviso">
                <img src={avatar} width={90} height={105} alt="Foto de perfil do usuario" />
                <div className="autorizacao">
                <label>{nome}</label>
                <label>{messagem.message}</label>
                </div>

            </div>
        );
    }
    return(
        <Main {...headerProps}>
            {render()}
        </Main>
    );
}