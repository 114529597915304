import React,{useState,useEffect} from "react";
import { parse,format } from "date-fns"; 
import { useHistory } from "react-router-dom";
import QrCode from "../Qrcode/Qrcode";
import QRCode  from "qrcode";
import { toast } from 'react-toastify';

import {get,put} from '../../store/config/register';
import Main from "../../components/template/Main";


let idempresa=1;

const headerProps = {
    icon: "users",
    title: "Cadastro de Visita",
  }

const initialState ={
    visitante: {
      id: 0,
      empresa_Id: idempresa,
      nome:'',
      dT_Nascimento: new Date(),
      email:'',
      celular: '',
      inicio: format(new Date(),'yyyy-MM-dd'),
      fim: format(new Date().setDate(new Date().getDate()),'yyyy-MM-dd'),
      foto: null,
      anfitriao_Id: 0,
      qrcode: null
    }
  }


  

export default function AgendaVisitante(props){

    const [visitante,setVisitante]=useState(initialState.visitante);
    const [src,SetSrc]=useState('');
    const [showPostPesquisa, setShowPostPesquisa] = useState(false);
    const id=props.match.params.id;
    const history = useHistory(); 

    function  updateField(event){
        const vis ={...visitante}
          
        vis[event.target.name] =event.target.value
          setVisitante(vis);
  }

  function  updateField(event){
    const vis ={...visitante}
      
    vis[event.target.name] =event.target.value
      setVisitante(vis);
}

useEffect(()=>{
  let inicio =parse(visitante.inicio,'yyyy-MM-dd',new Date());
  setVisitante({...visitante, fim: format(inicio.setDate(inicio.getDate()),'yyyy-MM-dd')});
},[visitante.inicio])


useEffect(() =>{
    
    if ( id !== undefined){
      setVisitante({...visitante, id: id}) ;
      
      buscaVisitante(id);
  }

  },[])

async function buscaVisitante(id){
    const vis=await get(`/Visitante/v1/${id}`);

  if(vis != null){
          
      
        setVisitante({...visitante,
        id: vis.id,
        empresa_Id: idempresa,
        nome: vis.nome,
        dT_Nascimento: parse(vis.dT_Nascimento,"yyyy-MM-dd'T'HH:mm:ss",new Date()),
        email: vis.email,
        celular: vis.celular,
        anfitriao_Id: vis.anfitriao_Id,
        anfitriao: vis.anfitriao,
        foto: vis.foto
      }); 


  
}
}

function togglePostModal(item){
    setShowPostPesquisa(!showPostPesquisa) //trocando de true pra false
    if(!showPostPesquisa === false){
      history.push("/Visitantes")
    }

}

function clear(){  
    history.push('/Visitantes')
  }

  async function GerarQrcode(){

    let num= Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    
    const result= await get(`/Visitante/v1/Qrcode/${num}`);

     if(result.qrcode === false){
      QRCode.toDataURL(num.toString()).then(SetSrc);

        setVisitante({...visitante,qrcode: num.toString()});
        visitante.qrcode =num.toString();
       }else {
        GerarQrcode();
       }  
  }

  async function save (){
    let inicio =parse(visitante.inicio,'yyyy-MM-dd',new Date());
   let agora = parse(format(new Date(),'yyyy-MM-dd'),'yyyy-MM-dd',new Date());

  if(inicio >= agora){
    await  GerarQrcode();
    await put(`/Visitante/v1/${visitante.id}`,visitante);
    await   toast.success("Visitante gravado com sucesso!");
    togglePostModal(visitante);
  }else {
    toast.error("Data inválida para visita!");
  }
     
}

    function render(){
        return (
            <div>
                <h2>{visitante.nome}</h2>
                <hr />
                 <label><strong>Data de liberação</strong></label><hr/>
            <div className="section">
                <div className="data1">
                  <label className="control-label">Data:</label>
                  <input type="date" name="inicio" className="form-control" 
                    value={visitante.inicio}
                    onChange={e => updateField(e)} />
                </div><br/>

                {/* <div class="data2">
                  <label className="control-label"><b>*</b>Até dia:</label>
                  <input type="date" name="fim" readOnly className="form-control"
                    value={visitante.fim}
                    onChange={e => updateField(e)} />
                </div> */}
            </div>

              
            <hr/>
            <label>* O campo não pode ser alterado</label>
            <div id="botao">
                  <button className="btn btn-primary ml-2" onClick={ async e =>  await save()}>
                    Salvar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => clear()}>
                    Cancelar
                  </button>
            </div>
            {showPostPesquisa && (
            <QrCode
            conteudo={{...visitante}}
            close={togglePostModal}
            />
            )}
            </div>
        );
    }
    return (
    <Main {...headerProps}>
        {render()}
    </Main>
    );
}