import React from "react";

const rows = [
  {
    id: { value: "" },
    description: { value: "Paytm" },
    DueDate: { value: "04/03/2020" }
  },
  {
    id: { value: "" },
    description: { value: "paypal" },
    DueDate: { value: "04/04/2021" }
  },
  {
    id: { value: "" },
    description: { value: "paypal" },
    DueDate: { value: "04/03/2020" }
  },
  {
    id: { value: "" },
    description: { value: "Paytm" },
    DueDate: { value: "04/03/2021" }
  },
  {
    id: { value: "" },
    description: { value: "Gpay" },
    DueDate: { value: "04/03/2020" }
  }
];

export default class App extends React.Component {
  state = {
    transformedRows: {}
  };

  componentDidMount = () => {
    const descriptionSortedArray = rows.reduce((acc, current) => {
      acc[current.description.value] = {
        ...acc[current.description.value],
        data: [...(acc[current.description.value]?.["data"] ?? []), current],
        visible: false
      };
      return acc;
    }, {});

    const sortRowsByDate = (rows) =>
      rows.sort(
        (a, b) => new Date(b.DueDate.value) - new Date(a.DueDate.value.data)
      );

    const transformedRows = Object.keys(descriptionSortedArray).reduce(
      (acc, current) => {
        acc[current] = {
          ...descriptionSortedArray[current],
          data: sortRowsByDate(descriptionSortedArray[current]["data"])
        };
        return acc;
      },
      {}
    );
    this.setState({ transformedRows });
  };

  handleToggle = (entry, visibility) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        transformedRows: Object.fromEntries(
          Object.entries(prevState.transformedRows).map(([key, value]) => {
            if (key === entry) {
              return [key, { ...value, visible: visibility }];
            } else {
              return [key, value];
            }
          })
        )
      };
    });
  };

  render() {
    const { transformedRows } = this.state;
    console.log(Object.entries(transformedRows));
    return (
      <div>
        <table>
          <tr>
            <th>id</th>
            <th>description</th>
            <th>duedate</th>
            <th></th>
          </tr>
          <tbody>
            {Object.entries(transformedRows).map(([key, { data, visible }]) => {
              if (data.length > 1) {
                return data.map((item, index) => (
                  <tr>
                    {(index === 0 || (index >= 1 && visible)) && (
                      <>
                        <td>{item.id.value}</td>
                        <td>{item.description.value}</td>
                        <td>{item.DueDate.value}</td>
                      </>
                    )}
                    {index === 0 && (
                      <td>
                        {
                          <button
                            onClick={() => {
                              this.handleToggle(key, !visible);
                            }}
                          >
                            toggle
                          </button>
                        }
                      </td>
                    )}
                  </tr>
                ));
              } else {
                return data.map((item) => (
                  <tr>
                    <td>{item.id.value}</td>
                    <td>{item.description.value}</td>
                    <td>{item.DueDate.value}</td>
                  </tr>
                ));
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
}