import React, {useEffect, useState} from 'react';
import Main from '../../components/template/Main';

import './Auth.css'
import logo from '../../assets/imgs/logo.png'
import ToastAnimated from '../../components/Toast/Toast'
import {post} from '../../store/config/register';
import {setLocalStorage,getLocalStorage} from '../../store/config/storage';
import {useHistory} from 'react-router-dom';
import { toast } from 'react-toastify';
import { authKey, secretKey} from "../../global"
import { Button } from 'react-bootstrap';
import CryptoJS from "crypto-js";
import { parse,format } from 'date-fns';
import { useAddToHomescreenPrompt } from "../Install/useAddToHomescreenPrompt.tsx";
import { useParams } from 'react-router-dom';

import usePWAInstall from 'use-pwa-install';


var initialState = {  showSignup: false,
    login: {
        email: '',
        senha:'',
        id_sistema: 1
    },
toast: {
    tipo: '',
    message: ''
} };

const headerProps = {
    icon: "key",
    title: "Login",
    subtitle: "Login do Sistema"
}

export default function Auth(props){

    const history = useHistory();
    const [login,setLogin]=useState({...initialState.login});
    const [error,setError]=useState('');
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false); 
    const { isInstalled, install } = usePWAInstall();
    const {id}=useParams(); 
    console.log(id);


    const hide = () => setVisibleState(false);
    function  updateField( event){
        const logi ={...login}
        logi[event.target.name] =event.target.value
        setLogin(logi);
    }
    let empresa =  getLocalStorage('empresa');

   

    useEffect(()=>{
        if(empresa !== undefined  && id === undefined){
           
            let emp =JSON.parse(empresa).empresa;
            history.push('/Auth/'+emp);
        }else if (empresa === undefined && id !== undefined){
            setLocalStorage("empresa",JSON.stringify({empresa: id}),11664000)
        }
       
        if(id === undefined){
        let resp =criptoID()   

            setLocalStorage("empresa",JSON.stringify({empresa: resp}),11664000)
            history.push('/Auth/'+resp);
        
        }

    },[])

    function criptoID(){
        return CryptoJS.AES.encrypt('1', secretKey).toString();
        // if(empresa === undefined){
        //     let emp =CryptoJS.AES.encrypt('1', secretKey).toString();
        //     id =emp;
        //     return emp;
           
        // }else{
        //     let emp =JSON.parse(empresa).empresa;
        //     id =emp;
        //     return emp;
        // }
       
    }

    useEffect(()=>{
        if (prompt) {
          setVisibleState(true);
        }
      },[prompt])

    function Validar( ){
        if(login.email === ''){
            setError('Preencha o login')
            return false;
        }

        if(login.senha === ''){
            setError('Preencha a senha')
            return false;
        }
        setError('')
        return true;
    }

    async function  signin(){
        if(Validar()) {
            
            const lo=await post('Login/v1/Validar', login);
            console.log(lo);
            if(lo.id === undefined){
                setError('usuário ou senha inválido!')
            }else {

                if(lo.confirmacao === false) {
                    setLocalStorage("messagem",JSON.stringify({message: "Foi enviado um email de confirmação. Entre no email e clique no link para confirmar o seu cadastro."}),11664000)
                   history.push(`/Autorizacao/${lo.nome}`);
                    window.location.reload();
                }else {
                    if(lo.status === true){
                
                        let resposta =lo.id+"/"+lo.email+"/"+lo.empresa_Id;
                        let data ={login_id: lo.id, data: format(Date.now(),"yyyy-MM-dd'T'HH:mm:ss") , empresa_Id:lo.empresa_Id}
                        await post('/LogAcesso/v1', data);
                        const auth = { ID: lo.id, nome: lo.nome, Token: CryptoJS.AES.encrypt(resposta, secretKey).toString(), Time: new Date().getTime() + (60000 * 11664000),Admin: lo.admin}
                        setLocalStorage(authKey,JSON.stringify(auth) ,11664000)
                        history.push('/Visitantes');
                        window.location.reload();
                    }else{
                        setLocalStorage("messagem",JSON.stringify({message:"Aguardando autorização do Condomínio..."}),5)
                    history.push(`/Autorizacao/${lo.nome}`);
                    window.location.reload();
                      
                  }
                }

                
            }

        }
       
                
    }

    function Cadastrar() {
        history.push('/Cadastrar')
    }
    
    // function montaUser(obj){

        
    //     let tokenApi= getLocalStorage("acessToken");
     
    //     return  {
    //      id: obj.id,
    //      id_grupo: obj.id_grupo,
    //      id_sistema: obj.id_sistema,
    //      login: obj.login,
    //      nome: obj.nome,
    //      empresa_id:obj.empresa_id,
    //      token: tokenApi
    //  }
    //      }

    function renderForm(){

        return (
        <div class="auth-content">
            <div class="auth-modal">
                <img src= {logo} width="200" alt="Logo"/>
                <hr/>
                 <div class="auth-title"> { 'Login'} </div>       
              <input  value={login.email} name="email" placeholder="Email" type="text" onChange={(e) =>updateField(e)}></input>
              <input  value={login.senha} name="senha" placeholder="Senha" type="password" onChange={(e) =>updateField(e)}></input>    
            <ToastAnimated />
             <button className='btn btn-primary' onClick={async () => signin()}>Entrar</button>
             {error !== '' ? (
                <div>
                <br />
                <label style={{color: '#FF0000'}}>{error}</label>
                </div>

             ): null
             }
             
             <hr />
             <div className='nova'>
             <button className='btn btn-success' onClick={Cadastrar}>Criar Nova Conta</button> 
             </div>
             {!isInstalled && (
                 
        <div className='nova'>

        <button className='btn btn-dark' style={{marginTop: "10px"}} onClick={install}>Instalar</button>
        </div>
      )}
                                                      
            </div>
        </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}