import React from "react";
import '../../pages/Qrcode/Qrcode.css'
import { del } from "../../store/config/register";
import { toast } from "react-toastify";

export default function Messagem({conteudo,close}) {
    async function Delete(Id){
      await  del(`/Visitante/v1/${Id}`)
      await toast.success('Visitante apagado.')
       window.location.reload();
      }

    return (
        <div className="modalQrcode">
            <div className="containerQrcode">
                <label>{conteudo.messagem}</label>
                <div id="botao">
                <button className="btn btn-primary ml-2" onClick={async()=> {await Delete(conteudo.id); }}>Sim</button>
                <button className="btn btn-secondary ml-2" onClick={close}>Não</button>
                </div>
                
            </div>
        </div>
    );
}